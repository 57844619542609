.quickmenu {
    &__container {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        width: 100%;
        flex: 1 1;
        padding: 20px;
        box-sizing: border-box;
        scrollbar-width: thin;
        scrollbar-color: #90a4ae #cfd8dc;

        &::-webkit-scrollbar {
            width: 11px;
        }

        &::-webkit-scrollbar-track {
            background: #cfd8dc;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #90a4ae;
            border-radius: 6px;
            border: 3px solid #cfd8dc;
        }
    }

    &__card {
        &-container {
            height: max-content;
            width: 100% !important;
            box-sizing: border-box;
            margin: 0 auto 40px;
            background-color: white;
            padding: 25px;
            border-radius: 25px;
            box-shadow: 5px 5px 10px rgb(0 0 0 / 30%);

            &-flex {
                display: flex;
            }
        }

        &-headingbox {
            margin-left: 15px;
        }

        &-chartbox {
            display: flex;
            justify-content: center;
            margin-top: 20px;
        }

        &-rightbox {
            margin: 30px auto;
            display: flex;
            align-items: center;
        }

        &-td {
            padding: 10px 15px;
            font-size: 16px;

            a {
                margin-left: 10px;
                text-decoration: none;
                width: 100%;
                color: black;
                font-size: 16px;
                font-weight: 600;
                display: inline-flex;
                align-items: center;
                gap: 0.5rem;

                &:hover {
                    text-decoration: underline;
                }
            }

            svg {
                height: 18px;
                width: 18px;
            }
        }

        &-legend {
            width: 15px;
            height: 15px;
            border-radius: 50%;

            &-invited {
                background-color: rgb(79, 202, 100);
            }

            &-submitted {
                background-color: rgb(84, 197, 235);
            }

            &-shortlisted {
                background-color: rgb(255, 183, 74);
            }

            &-rejected {
                background-color: red;
            }

            &-hired {
                background-color: rgb(34, 36, 44);
            }
        }

        &-heading {
            font-size: 19px;
        }

        &-count {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            padding: 6px 0px;
            line-height: 16px;
            color: rgb(134, 142, 150);
        }

        &-fulllist {
            font-size: 19px;
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 500;
            color: #479bd2;
        }

        &-link {
            text-decoration: none;
        }

        &-headingrow {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;
        }
    }

    &__table {
        &-head {
            span {
                font-family: "Poppins";
                font-size: 16px;
                font-weight: 600;
                color: #334d6e;
            }
        }

        &-cell {
            &:nth-child(1) {
                width: 10%;
            }

            &:nth-child(2) {
                width: 30%;
            }

            &:nth-child(3) {
                width: 30%;
            }

            &:nth-child(4) {
                width: 20%;
            }

            &:nth-child(5) {
                width: 10%;
            }

            span {
                font-family: "Mulish";
                font-size: 14px;
                font-weight: 400;
                color: #404040;
            }
        }
    }

    &__rating {
        &-innerdiv {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 5vh;

            & > div {
                margin: 0 5px;
            }
        }

        &-legend {
            width: 8vh;
            height: 8vh;
            border-radius: 50%;
            background: #34bcd4;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.8vh;
        }

        &-text {
            text-align: center;
            font-size: 20px;

            &-primary {
                color: #334d6e;
                font-weight: 600;
            }

            &-secondary {
                color: #888;
                font-weight: 400;
            }
        }
    }

    &__copyId {
        position: relative;
        cursor: pointer;
        display: inline-block;

        &-wrapper {
            display: inline-flex;
            align-items: center;
        }

        .svg-wrapper {
            display: inline-flex;
            align-items: center;

            svg {
                width: 20px;
                height: 20px;
                margin-left: 0.5rem;
            }

            &:hover {
                .quickmenu__copyId-text {
                    display: inline-block;
                }
            }
        }

        &-text {
            display: none;
            align-items: center;
            border-radius: 0.5rem;
            position: absolute;
            top: 125%;
            left: 50%;
            padding: 0.2rem 0.5rem;
            background-color: #e7e7e7;
            min-width: 50px;
            width: max-content;
            margin-left: 0.25rem;
            font-size: 12px !important;
        }
    }
}

.modal {
    &__heading-secondary {
        display: flex;
        align-items: center;

        span {
            margin-right: 0.5rem;
        }

        img {
            cursor: pointer;
        }
    }
}

.create-job-opening {
    padding: 0 20px;
    width: 700px;
}

.modal-job-opening {
    .name-role-input {
        min-width: 350px;
    }

    .brief-desc-input {
        margin-top: 10px;
        padding-bottom: 25px;
        border-bottom: 1px solid #c4c4c4;
        min-width: 350px;
    }
}

@media screen and (max-width: 768px) {
    .quickmenu {
        &__container {
            padding-top: 10px;
            padding-bottom: 52px;
            overflow-y: unset;
        }

        &__card-container {
            padding: 1rem 20px;
            margin-bottom: 1rem;

            &:first-child {
                padding-bottom: 0;
            }
        }

        &__actions-box {
            display: flex;
            align-items: center;
            position: fixed;
            bottom: 51px;
            left: 0;
            background-color: white;
            width: 100%;
            padding: 0.5rem 0;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;

            &-btn {
                font-weight: bold;
                font-size: 16px;
                padding: 0.5rem 1rem;
                color: #fff;
                width: fit-content;
                border-radius: 8px;

                &:first-child {
                    margin-right: 1rem;
                }
            }
        }

        &__card-headingbox {
            margin-left: 0;
            width: 100%;
        }

        &__card-leftbox {
            width: 100%;
        }

        &__card-leftbox #chart1 {
            margin-top: 0.5rem;
            margin-left: 0;
            width: 100%;
        }

        &__card-rightbox {
            display: none;
        }

        &__card-link {
            display: flex;
            align-items: center;
            color: #707070;

            svg {
                margin-right: 0.2rem;
            }
        }

        &__card-link-box {
            display: none;
        }

        &__card-showmore {
            display: flex;
            margin-top: 1rem;
            justify-content: center;
            font-weight: bold;
        }
    }

    .modal__btnbox {
        gap: 5%;
    }

    .chatbot-link-modal.modal__container {
        width: unset;
        max-width: 100%;
        padding: 1rem;
        border-radius: 8px;
    }

    .chatbot-link-modal > .modal__heading-secondary {
        word-break: break-all;
    }

    .create-job-opening {
        width: 100%;
        padding: 0;
    }

    .modal-job-opening {
        max-width: 100%;
        border-radius: 8px;
        box-sizing: border-box;

        .name-role-input {
            min-width: 100%;
        }

        .brief-desc-input {
            min-width: 100%;
        }
    }

    .quickmenu-box__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1rem 0;
    }

    .quickmenu-box__row-left {
        display: flex;
        align-items: center;
    }

    .quickmenu-box-row-left-text-box {
        margin-left: 1rem;
    }

    .quickmenu-box__row-left-image-box {
        position: relative;
    }

    .quickmenu-box__row-left-image-box img {
        width: 16px;
        height: 16px;
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .quickmenu-box__row-left-text-name {
        font-weight: 600;
        font-size: 15px;
        color: #404040;
    }

    .quickmenu-box__row-left-text-role {
        font-weight: 600;
        font-size: 12px;
        color: #858585;
        text-align: end;
    }

    .quickmenu-box__row-right {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
    }

    .quickmenu-box__invite-btn {
        background: #197bbd;
        border-radius: 7px;
        outline: none;
        border: none;
        color: white;
        padding: 0.5rem 2rem;
        font-weight: 700;
        font-size: 18px;
        text-align: center;
        margin-top: auto;
    }

    .quickmenu-box__drawer {
        max-height: 50vh !important;
        height: 100% !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
            1px -3px 4px rgba(0, 0, 0, 0.25);
        border-radius: 40px 40px 1px 1px;
        overflow-y: auto;
    }
}
