.applicant {
    &-loading-wrapper {
        flex: 1;
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-loader {
        border: 4px solid #ddd;
        -webkit-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
        border-top: 4px solid #555;
        border-radius: 50%;
        width: 32px;
        height: 32px;
    }

    &-profile {
        flex: 1;
        padding: 20px;
        box-sizing: border-box;
        min-height: 100vh;
        display: flex;
        flex-direction: column;

        &-wrapper {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            background-color: #fff;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            margin-top: 20px;
            padding: 20px;

            &-header {
                display: flex;
                align-items: center;
                padding: 0.5rem 0 1rem 0;
                margin-bottom: 1.8rem;
                border-bottom: 1px solid #ececec;

                .left-arrow,
                .right-arrow {
                    width: 50px;
                    height: 50px;
                    background-color: transparent;
                    border: none;

                    &:disabled {
                        opacity: 0.4;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .candidate {
                    padding: 0 20px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex: 1;

                    &-info {
                        display: flex;
                        align-items: center;

                        &-basic {
                            padding-left: 20px;

                            .name {
                                color: #083a50;
                                font-size: 20px;
                                font-weight: 600;
                            }

                            .email {
                                color: #969696;
                                font-size: 16px;
                                font-weight: normal;
                            }
                        }
                    }

                    &-data {

                        &-status,
                        &-score {
                            color: #969696;

                            span {
                                color: #014954;
                            }
                        }
                    }
                }
            }

            &-body {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                &-left {
                    width: 28%;
                    margin-top: 5px;

                    &-btn {
                        width: 100%;
                        background-color: #fff;
                        border-radius: 1rem;
                        color: #535353;
                        font-size: 18px;
                        font-weight: 700;
                        border: none;
                        margin-bottom: 1.5rem;
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
                            rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
                        cursor: pointer;

                        &.active,
                        &:hover {
                            background-color: #479bd2;
                            color: #fff;

                            a {
                                color: #fff;
                            }
                        }

                        &:disabled {
                            background-color: #8b8b8b;
                            color: #fff;
                            cursor: default;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }

                        a {
                            padding: 1rem;
                            display: block;
                            text-decoration: none;
                            color: #535353;
                        }
                    }
                }

                &-right {
                    width: 68%;
                    height: 800px;
                    overflow-y: auto;

                    &-wrapper {
                        margin: 10px;
                        padding: 1rem 1.5rem;
                        border-radius: 30px;
                        margin-bottom: 2rem;
                        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
                            rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

                        .header {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            &-title {
                                color: #535353;
                                font-size: 18px;
                                font-weight: 700;

                                &-beta {
                                    position: relative;
                                    top: -9px;
                                    font-size: 7px;
                                    letter-spacing: 1px;
                                    font-weight: 600;
                                    background-color: #479bd2;
                                    padding: 3px 6px;
                                    color: #fff;
                                    margin-left: 3px;
                                    border-radius: 20px;
                                }
                            }

                            &-status {
                                font-size: 16px;
                                font-weight: 500;

                                &.pass {
                                    color: #1f8700;
                                }

                                &.fail {
                                    color: #fe1c1c;
                                }
                            }
                        }

                        .quest-ans-row {
                            border-bottom: 1px solid #ececec;
                            padding: 1rem 0;
                            display: flex;

                            .quest-no {
                                font-weight: 700;
                                font-size: 16px;
                                color: #393939;
                            }

                            .quest-ans {
                                margin-left: 0.5rem;

                                .quest {
                                    font-weight: 500;
                                    font-size: 15px;
                                    color: #393939;
                                }

                                .ans {
                                    margin-top: 0.5rem;
                                    font-weight: 500;
                                    font-size: 15px;
                                    color: #878787;
                                }
                            }
                        }

                        .no-data {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            img {
                                display: block;
                                width: 250px;
                                height: auto;
                            }

                            p {
                                font-size: 18px;
                                font-weight: 700;
                                color: #334d6e;
                            }
                        }

                        .show-more-btn {
                            text-align: center;
                            font-weight: 600;
                            font-size: 16px;
                            text-decoration-line: underline;
                            color: #333333;
                            margin-top: 8px;
                            cursor: pointer;
                        }

                        .stat {
                            display: flex;
                            align-items: center;
                            gap: 0.5rem;
                            padding: 1rem 0;
                            font-size: 15px;
                            border-bottom: 1px solid #ececec;
                        }

                        .stat2 {
                            padding: 1rem 0;
                            font-size: 15px;
                            border-bottom: 1px solid #ececec;
                        }
                    }
                }
            }
        }
    }
}

.video-ans-modal {
    display: flex;
    align-items: center;

    &-left {
        padding-top: 1rem;
        padding-right: 1.5rem;
        border-right: 1px solid rgba(107, 107, 107, 0.72);

        .video-question-wrapper {
            cursor: pointer;
            margin-bottom: 2rem;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            padding: 1rem 1.5rem;

            &:last-child {
                margin-bottom: 0;
            }

            &.active {
                background-color: rgba(71, 155, 210, 0.14);
            }

            .question {
                font-weight: 500;
                font-size: 12px;
                color: #919191;
                margin-top: 0.5rem;

                &-no {
                    font-weight: 600;
                    font-size: 16px;
                    color: #333333;
                }
            }
        }
    }

    &-right {
        padding-left: 1.5rem;

        .heading {
            font-weight: 700;
            font-size: 18px;
            color: #535353;
        }
    }
}