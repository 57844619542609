.overlayWrap {
    position: relative;
}

.overlay {
    position: absolute;
    inset: 0px;
    z-index: 10;
}

.overlayBg {
    position: absolute;
    border-radius: 0.25rem;
    background-color: #f8f9fa;
    inset: 0px;
    opacity: 0.85;
    backdrop-filter: blur(2px);
}

.overlayContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}